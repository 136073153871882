import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form } from 'reactstrap';

import { deleteConfirmationToast } from '../../../../components/deleteConfirmationToast';
import IndexFieldSelect from '../../../../components/form/IndexFieldSelect';
import InputFormField from '../../../../components/form/InputFormField';
import { HttpResponseStatus } from '../../../../constants/httpResponseStatus';
import AppContext from '../../../../context/Context';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';
import { switchboardZoneApi } from '../../../../helpers/api/switchboard/switchboardZoneApi';
import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import { ProductDestination } from '../../../../helpers/enums/productDestinationEnum';
import { switchboardZoneFormSchema } from '../../../../helpers/formValidations/switchboardZoneFormSchema';
import { checkUserPermissions } from '../../../../helpers/utils/checkUserPermissions';
import CheckUserPermissions from '../../../../hoc/checkUserPermissions';
import ProtectionSelect from './ProtectionSelect';

export default function ZoneModalForm({
  defaultValues,
  isOpen,
  toggle,
  zone,
  switchboard,
  refreshSwitchboard,
  isEdit
}) {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(switchboardZoneFormSchema)
  });

  useEffect(() => {
    if (isOpen) {
      form.reset({
        index: zone.index ?? switchboard.zones.length + 1,
        label: zone.label ?? '',
        protection: zone.protection ?? null
      });
    }
  }, [isOpen]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const newData = {
        ...data,
        protection: data.protection.id
      };
      if (isEdit) {
        await switchboardZoneApi.updateZone(
          switchboard.projectId,
          zone.id,
          newData
        );
      } else {
        await switchboardZoneApi.addZone(switchboard.projectId, newData);
      }
      toast.success(t('Zone successfully saved.'));
      refreshSwitchboard();
      toggle();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  const onDelete = async () => {
    try {
      await switchboardZoneApi.deleteZone(switchboard.projectId, zone.id);
      toast.success(t('Zone successfully deleted.'));
      refreshSwitchboard();
      toggle();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const showDeleteConfirmationToast = () => {
    deleteConfirmationToast({
      title: `${t('Please confirm you want to delete')} "${zone.label}" ${t('zone')} ?`,
      onClick: () => onDelete(),
      buttonText: t('Yes, delete!')
    });
  };

  const checkPermission = (permissions) => {
    return checkUserPermissions({
      isAdmin: user?.isAdmin,
      userPermissions: user?.permissions,
      permissions: permissions
    });
  };

  const restoreDeletedZone = async () => {
    try {
      const response = await switchboardZoneApi.restoreZone(
        switchboard.projectId,
        zone.id
      );
      if (response.status === HttpResponseStatus.Ok) {
        toast.success(t('Zone successfully restored.'));
        toggle();
        refreshSwitchboard();
      }
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <IndexFieldSelect
          items={switchboard.zones}
          isEdit={isEdit}
          isDisabled={
            isLoading ||
            zone.isDeleted ||
            !checkPermission([PermissionType.SWITCHBOARD.ZONE.VIEW])
          }
        />
        <InputFormField
          isDisabled={
            isLoading ||
            zone.isDeleted ||
            !checkPermission([PermissionType.SWITCHBOARD.ZONE.VIEW])
          }
          label="label"
          placeholder="eg. First Floor"
          displayLabel="Label"
          isRequired
        />
        <ProtectionSelect
          isDisabled={
            isLoading ||
            zone.isDeleted ||
            !checkPermission([PermissionType.SWITCHBOARD.ZONE.VIEW])
          }
          destination={ProductDestination.ZONE}
          manufacturerId={switchboard.manufacturerId}
        />
        <CheckUserPermissions
          permissions={[PermissionType.SWITCHBOARD.ZONE.MODIFY]}
        >
          <div className="d-flex justify-content-between mt-4">
            <div>
              {zone.isDeleted ? (
                <Button
                  type="button"
                  disabled={isLoading}
                  color="falcon-success"
                  onClick={restoreDeletedZone}
                >
                  {t('Restore')}
                </Button>
              ) : (
                <Button
                  type="button"
                  disabled={isLoading}
                  color="falcon-danger"
                  onClick={showDeleteConfirmationToast}
                >
                  {t('Delete')}
                </Button>
              )}
            </div>
            {!zone.isDeleted && (
              <div>
                <Button
                  type="submit"
                  disabled={isLoading}
                  color="falcon-primary"
                >
                  {isLoading ? 'Saving' : 'Save'}
                </Button>
              </div>
            )}
          </div>
        </CheckUserPermissions>
      </Form>
    </FormProvider>
  );
}

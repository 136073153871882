export const projects = {
  en: {
    translations: {
      Page: 'Page',
      of: 'of',
      Previous: 'Previous',
      Next: 'Next',
      Documents: 'Documents',
      'Switchboard attach': 'Switchboard attach',
      'Switchboard documentation': 'Switchboard documentation',
      'Cable journal': 'Cable journal',
      'Purchases list': 'Purchases list',
      'Tags clamps': 'Tags clamps',
      'Tags conductors': 'Tags conductors',
      'Tags modular equipment': 'Tags modular equipment',
      Status: 'Status',
      'Current status': 'Current status',
      'Status Action': 'Status Action',
      'Quickly update the project status (a notification will be sent to admins and collaborators)':
        'Quickly update the project status (a notification will be sent to admins and collaborators)',
      Summary: 'Summary',
      'Project Id': 'Project Id',
      Project: 'Project',
      hour: 'hour',
      Offer: 'Offer',
      'Offer profile': 'Offer profile',
      'Select offer profile': 'Select offer profile',
      'Generate Offer': 'Generate Offer',
      'Recreate Offer': 'Recreate Offer',
      "You don't have any builded offers yet, try to generate one.":
        "You don't have any builded offers yet, try to generate one.",
      'Unit Price': 'Unit Price',
      History: 'History',
      Date: 'Date',
      Comments: 'Comments',
      'Post a new comment': 'Post a new comment',
      'Customer visible': 'Customer visible',
      'Send email notification': 'Send email notification',
      'The comment should be at least 20 chars long':
        'The comment should be at least 20 chars long',
      Post: 'Post',
      'No comments posted yet': 'No comments posted yet',
      Comment: 'Comment',
      'Attach a file': 'Attach a file',
      'Drop files here to start uploading':
        'Drop files here to start uploading',
      Attachments: 'Attachments',
      View: 'View',
      Configure: 'Configure',
      new: 'new',
      configuration: 'configuration',
      'advance payment': 'advance payment',
      purchase: 'purchase',
      documentation: 'documentation',
      build: 'build',
      wiring: 'wiring',
      'quality assurance': 'quality assurance',
      programming: 'programming',
      'visual quality assurance': 'visual quality assurance',
      payment: 'payment',
      shipped: 'shipped',
      'Generate Documentation': 'Generate Documentation'
    }
  },
  ro: {
    translations: {
      Page: 'Pagina',
      of: 'din',
      Previous: 'Anterior',
      Next: 'Urmator',
      Documents: 'Documente',
      'Switchboard attach': 'Anexa tablou',
      'Switchboard documentation': 'Documentație tablou',
      'Cable journal': 'Jurnal cabluri',
      'Purchases list': 'Lista achiziții',
      'Tags clamps': 'Etichete sir de cleme',
      'Tags conductors': 'Etichete tile conductori',
      'Tags modular equipment': 'Etichete aparataj modular',
      Status: 'Status',
      'Current status': 'Status curent',
      'Status Action': 'Actiune status',
      'Quickly update the project status (a notification will be sent to admins and collaborators)':
        'Actualizeaza rapid statusul proiectului (o notificare va fi trimisa catre admini si colaboratori)',
      Summary: 'Sumar',
      'Project Id': 'Id Proiect',
      Project: 'Proiect',
      hour: 'ora',
      Offer: 'Oferta',
      'Offer profile': 'Profil oferta',
      'Select offer profile': 'Selecteaza profil oferta',
      'Generate Offer': 'Genereaza Oferta',
      'Recreate Offer': 'Regenereaza Oferta',
      "You don't have any builded offers yet, try to generate one.":
        'Nu ai nici o oferta generata inca, incearca sa generezi una.',
      'Unit Price': 'Pret Unitar',
      History: 'Istoric',
      Date: 'Data',
      Comments: 'Comentarii',
      'Post a new comment': 'Posteaza un nou comentariu',
      'Customer visible': 'Vizibil client',
      'Send email notification': 'Trimite notificare email',
      'The comment should be at least 20 chars long':
        'Comentariul trebuie sa aiba cel putin 20 de caractere',
      Post: 'Posteaza',
      'No comments posted yet': 'Nu sunt comentarii postate inca',
      Comment: 'Comentariu',
      'Attach a file': 'Ataseaza un fisier',
      'Drop files here to start uploading':
        'Trage fisierele aici pentru a incepe upload-ul',
      Attachments: 'Atasamente',
      View: 'Vizualizeaza',
      Configure: 'Configureaza',
      new: 'nou',
      configuration: 'configurare',
      'advance payment': 'plata in avans',
      purchase: 'cumparare',
      documentation: 'documentatie',
      build: 'construire',
      wiring: 'cablare',
      'quality assurance': 'asigurare calitate',
      programming: 'programare',
      'visual quality assurance': 'asigurare calitate vizuala',
      payment: 'plata',
      shipped: 'expediat',
      'Generate Documentation': 'Genereaza Documentatia'
    }
  },
  de: {
    translations: {
      Page: 'Seite',
      of: 'von',
      Previous: 'Vorherige',
      Next: 'Nächste',
      Documents: 'Dokumente',
      'Switchboard attach': 'Schaltschrank anhängen',
      'Switchboard documentation': 'Schaltschrank dokumentation',
      'Cable journal': 'Kabelprotokoll',
      'Purchases list': 'Einkaufsliste',
      'Tags clamps': 'Tags Klemmen',
      'Tags conductors': 'Tags Leiter',
      'Tags modular equipment': 'Tags modulare Ausrüstung',
      Status: 'Status',
      'Current status': 'Aktueller Status',
      'Status Action': 'Statusaktion',
      'Quickly update the project status (a notification will be sent to admins and collaborators)':
        'Aktualisieren Sie schnell den Projektstatus (eine Benachrichtigung wird an Admins und Mitarbeiter gesendet)',
      Summary: 'Zusammenfassung',
      'Project Id': 'Projekt-ID',
      Project: 'Projekt',
      hour: 'Stunde',
      Offer: 'Angebot',
      'Offer profile': 'Angebotsprofil',
      'Select offer profile': 'Angebotprofil auswählen',
      'Generate Offer': 'Angebot generieren',
      'Recreate Offer': 'Angebot neu erstellen',
      "You don't have any builded offers yet, try to generate one.":
        'Sie haben noch keine Angebote erstellt, versuchen Sie, eines zu generieren.',
      'Unit Price': 'Stückpreis',
      History: 'Geschichte',
      Date: 'Datum',
      Comments: 'Kommentare',
      'Post a new comment': 'Posten Sie einen neuen Kommentar',
      'Customer visible': 'Kunde sichtbar',
      'Send email notification': 'E-Mail-Benachrichtigung senden',
      'The comment should be at least 20 chars long':
        'Der Kommentar sollte mindestens 20 Zeichen lang sein',
      Post: 'Posten',
      'No comments posted yet': 'Noch keine Kommentare veröffentlicht',
      Comment: 'Kommentar',
      'Attach a file': 'Datei anhängen',
      'Drop files here to start uploading':
        'Ziehen Sie Dateien hierher, um mit dem Hochladen zu beginnen',
      Attachments: 'Anhänge',
      View: 'Anzeigen',
      Configure: 'Konfigurieren',
      new: 'neu',
      configuration: 'konfiguration',
      'advance payment': 'vorauszahlung',
      purchase: 'kauf',
      documentation: 'dokumentation',
      build: 'bauen',
      wiring: 'verdrahtung',
      'quality assurance': 'qualitätssicherung',
      programming: 'programmierung',
      'visual quality assurance': 'visuelle qualitätssicherung',
      payment: 'zahlung',
      shipped: 'versendet',
      'Generate Documentation': 'Dokumentation generieren'
    }
  }
};

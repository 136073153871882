import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import PageHeader from '../../components/common/PageHeader';
import { projectApi } from '../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { switchboardApi } from '../../helpers/api/switchboard/switchboardApi';
import ShowDeletedCheckbox from './components/ShowDeletedCheckbox';
import SwitchboardHeaderButtons from './components/SwitchboardHeaderButtons';
import ConsumerModal from './components/switchboardModals/ConsumerModal';
import SubzoneModal from './components/switchboardModals/SubzoneModal';
import SwitchboardSettingsModal from './components/switchboardModals/SwitchboardSettingsModal';
import ZoneModal from './components/switchboardModals/ZoneModal';
import SwitchboardPhasesDetails from './components/SwitchboardPhasesDetails';
import ZonesCardsDisplay from './components/zonesColumns/ZonesCardsDisplay';
import { switchboardHelpers } from './switchBoardHelpers';

const defaultSwitchboard = {
  label: '',
  mountType: 0,
  connectionType: 0,
  projectId: null,
  manufacturerId: null,
  switchboardManufacturerId: null,
  protection: {
    id: null,
    label: null
  },
  cablesShouldHaveTileLabels: false,
  forceCableCode: '',
  busCableCode: '',
  networkCableCode: '',
  switchboardWidth: 0,
  switchboardHeight: 0,
  horizontalModulesPerRow: 0,
  verticalModulesPerGenericRow: 0,
  numberOfGenericRows: 0,
  verticalModulesPerClampRow: 0,
  numberOfClampRows: 0,
  numberOfReservedRows: 0,
  numberOfReservedClampRows: 0,
  hasCounterTub: false,
  zones: [],
  phasesDetails: {},
  ...switchboardHelpers
};

const defaultState = {
  isZoneModalOpen: false,
  zoneModalData: {},
  isSubzoneModalOpen: false,
  subzoneModalData: {},
  isConsumerModalOpen: false,
  consumerModalData: {},
  movingConsumerId: null,
  isSwitchboardSettingsModalOpen: false
};

const SwitchboardScreen = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState({});
  const [switchboard, setSwitchboard] = useState(defaultSwitchboard);
  const [state, setState] = useState(defaultState);
  const [showDeleted, setShowDeleted] = useState(false);

  const fetchSwitchboard = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await switchboardApi.getSwitchboardsByProjectId(
        projectId,
        { includeDeleted: showDeleted }
      );
      setSwitchboard({ ...response.data, ...switchboardHelpers });
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [projectId, showDeleted]);

  const fetchProject = useCallback(async () => {
    try {
      const response = await projectApi.getProjectById(projectId);
      setProject(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, [projectId]);

  useEffect(() => {
    fetchProject();
    fetchSwitchboard();
  }, [fetchProject, fetchSwitchboard]);

  const toggleZoneModal = (zone = {}) => {
    setState({
      ...state,
      isZoneModalOpen: !state.isZoneModalOpen,
      zoneModalData: zone
    });
  };

  const toggleSubzoneModal = (subzone = {}) => {
    setState({
      ...state,
      isSubzoneModalOpen: !state.isSubzoneModalOpen,
      subzoneModalData: subzone
    });
  };

  const toggleConsumerModal = (consumer = {}) => {
    setState({
      ...state,
      isConsumerModalOpen: !state.isConsumerModalOpen,
      consumerModalData: consumer
    });
  };

  const toggleSwitchboardSettingsModal = () => {
    setState({
      ...state,
      isSwitchboardSettingsModalOpen: !state.isSwitchboardSettingsModalOpen
    });
  };

  return (
    <>
      <PageHeader
        title={project.name || '...'}
        controls={() => (
          <div className="d-flex flex-wrap justify-content-end gap-4">
            <SwitchboardPhasesDetails
              phasesDetails={switchboard.phasesDetails}
            />
            <SwitchboardHeaderButtons
              projectId={projectId}
              toggleSwitchboardSettingsModal={toggleSwitchboardSettingsModal}
            />
            {switchboard.hasBeenSet && (
              <ShowDeletedCheckbox
                showDeleted={showDeleted}
                setShowDeleted={setShowDeleted}
              />
            )}
          </div>
        )}
      />

      {switchboard.hasBeenSet && (
        <Row
          className="mb-2"
          style={
            isLoading ? { pointerEvents: 'none', userSelect: 'none' } : null
          }
        >
          <Col>
            <ZonesCardsDisplay
              switchboard={switchboard}
              fetchSwitchboard={fetchSwitchboard}
              toggleZoneModal={toggleZoneModal}
              toggleConsumerModal={toggleConsumerModal}
              toggleSubzoneModal={toggleSubzoneModal}
            />
          </Col>
        </Row>
      )}

      {state.isZoneModalOpen && (
        <ZoneModal
          switchboard={switchboard}
          zone={state.zoneModalData}
          isOpen={state.isZoneModalOpen}
          toggle={() => toggleZoneModal()}
          refreshSwitchboard={fetchSwitchboard}
        />
      )}

      {state.isSubzoneModalOpen && (
        <SubzoneModal
          switchboard={switchboard}
          subzone={state.subzoneModalData}
          isOpen={state.isSubzoneModalOpen}
          toggle={() => toggleSubzoneModal()}
          refreshSwitchboard={fetchSwitchboard}
        />
      )}

      {state.isConsumerModalOpen && (
        <ConsumerModal
          switchboard={switchboard}
          consumer={state.consumerModalData}
          isOpen={state.isConsumerModalOpen}
          toggle={() => toggleConsumerModal()}
          refreshSwitchboard={fetchSwitchboard}
        />
      )}

      {state.isSwitchboardSettingsModalOpen && (
        <SwitchboardSettingsModal
          switchboard={switchboard}
          isOpen={state.isSwitchboardSettingsModalOpen}
          toggle={() => toggleSwitchboardSettingsModal()}
          refreshSwitchboard={fetchSwitchboard}
        />
      )}
    </>
  );
};

export default SwitchboardScreen;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import ConfirmationModal from '../../../../components/confirmationModal/ConfirmationModal';
import { offersApi } from '../../../../helpers/api/offersApi';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';

export default function CreateOfferButtons({
  project,
  offer,
  resetOffer,
  offerProfile,
  setUpdateOffer,
  setIsDocumentationGenerated,
  fetchProject
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRecreateOffer = () => {
    setIsModalOpen(false);
    resetOffer();
  };

  const handleOfferGeneration = async () => {
    setIsLoading(true);
    try {
      await offersApi.buildProjectOffer(project.id, offerProfile);
      setIsDocumentationGenerated(true);
      setUpdateOffer(true);
      fetchProject();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="d-flex justify-content-end gap-2">
        {offer && offerProfile ? (
          <Button
            className="bg-primary fs--1"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            {t('Recreate Offer')}
          </Button>
        ) : (
          <Button
            className="bg-success fs--1"
            onClick={handleOfferGeneration}
            disabled={!offerProfile || isLoading}
          >
            {t('Generate Offer')}
          </Button>
        )}
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        titleText="You are about to recreate the offer"
        bodyText="This will delete the current offer and create a new one. Are you sure you want to continue?"
        cancelText="Cancel"
        confirmText="Recreate"
        onConfirm={handleRecreateOffer}
        confirmButtonColor="danger"
      />
    </>
  );
}

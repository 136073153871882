import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Label } from 'reactstrap';

export default function SearchInput({
  searchValue,
  setSearchValue,
  onSearch,
  placeholder
}) {
  const { t } = useTranslation();
  const handleInputChange = (value) => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      onSearch(value);
    }, 300),
    []
  );

  const handleSearchClear = () => {
    setSearchValue('');
    debouncedSearch('');
  };

  return (
    <div>
      <Label for="searchInput">{t('Search')}</Label>
      <div className="position-relative">
        <Input
          id="searchInput"
          name="searchInput"
          value={searchValue}
          onChange={({ target }) => handleInputChange(target.value)}
          placeholder={
            placeholder &&
            placeholder
              .split(', ')
              .map((word) => t(word))
              .join(', ')
          }
          className="fs--1"
          size={placeholder.length}
          type="text"
          autoComplete="off"
          style={{ minWidth: '200px' }}
        />
        {searchValue && (
          <button
            className="btn btn-link position-absolute"
            style={{ right: 0, top: 0 }}
            onClick={handleSearchClear}
          >
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </div>
    </div>
  );
}

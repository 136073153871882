import InputFormField from '../../InputFormField';
import InputFormFieldFloatValue from '../../InputFormFieldFloatValue';
import MeasurementUnitSelect from './MeasurementUnitSelect';
import ProductCategorySelect from './ProductCategory/ProductCategorySelect';
import ProductDestinationSelect from './ProductDestinationSelect';
import ProductGroupSelect from './ProductGroupSelect';
import ProductManufacturerSelect from './ProductManufacturerSelect';

export default function ProductFormMainFields({ isLoading }) {
  return (
    <>
      <InputFormField
        isDisabled={isLoading}
        isRequired
        label="label"
        displayLabel="Label"
      />
      <InputFormField
        isDisabled={isLoading}
        isRequired
        label="sku"
        displayLabel="SKU"
        placeholder="ABC-1234"
      />
      <InputFormField
        isDisabled={isLoading}
        label="description"
        displayLabel="Description"
      />
      <ProductCategorySelect isRequired isDisabled={isLoading} />
      <ProductManufacturerSelect isRequired isDisabled={isLoading} />
      <InputFormField
        isDisabled={isLoading}
        isRequired
        label="manufacturerSku"
        displayLabel="Manufacturer SKU"
        placeholder="ABC-1234"
      />
      <ProductGroupSelect isDisabled={isLoading} />
      <ProductDestinationSelect isDisabled={isLoading} isRequired />
      <InputFormFieldFloatValue
        isRequired
        label="price"
        displayLabel="Price"
        placeholder="0.00"
        isDisabled={isLoading}
        inputGroupText="EUR"
      />
      <InputFormFieldFloatValue
        isRequired
        label="sellPrice"
        displayLabel="Sell Price"
        placeholder="0.00"
        isDisabled={isLoading}
        inputGroupText="EUR"
      />
      <MeasurementUnitSelect isRequired isDisabled={isLoading} />
    </>
  );
}

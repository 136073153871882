export const getSwitchboardSettingsFormValues = (switchboard) => {
  return {
    label: switchboard?.label,
    mountType: switchboard?.mountType,
    connectionType: switchboard?.connectionType,
    manufacturerId: switchboard?.manufacturerId,
    switchboardManufacturerId: switchboard?.switchboardManufacturerId,
    protection: switchboard?.protection,
    cablesShouldHaveTileLabels: switchboard?.cablesShouldHaveTileLabels,
    forceCableCode: switchboard?.forceCableCode,
    busCableCode: switchboard?.busCableCode,
    networkCableCode: switchboard?.networkCableCode,
    switchboardWidth: switchboard?.switchboardWidth,
    switchboardHeight: switchboard?.switchboardHeight,
    horizontalModulesPerRow: switchboard?.horizontalModulesPerRow,
    verticalModulesPerGenericRow: switchboard?.verticalModulesPerGenericRow,
    numberOfGenericRows: switchboard?.numberOfGenericRows,
    verticalModulesPerClampRow: switchboard?.verticalModulesPerClampRow,
    numberOfClampRows: switchboard?.numberOfClampRows,
    verticalModulesPerReservedRow: switchboard?.verticalModulesPerReservedRow,
    numberOfReservedRows: switchboard?.numberOfReservedRows,
    hasCounterTub: switchboard?.hasCounterTub,
    switchboardProduct: getSwitchboardProductFormValues(
      switchboard?.switchboardProduct
    )
  };
};

export const getSwitchboardProductFormValues = (switchboardProduct) => {
  return {
    rearPanel: switchboardProduct?.rearPanel,
    counter: switchboardProduct?.counter,
    frame: switchboardProduct?.frame,
    distributionBoard: switchboardProduct?.distributionBoard,
    support: switchboardProduct?.support,
    rail: switchboardProduct?.rail,
    slottedPlate: switchboardProduct?.slottedPlate,
    blindPlate: switchboardProduct?.blindPlate,
    reservedBlindPlate: switchboardProduct?.reservedBlindPlate,
    counterPlate: switchboardProduct?.counterPlate
  };
};

export const getNullSwitchboardProductFormValues = () => {
  return {
    rearPanel: null,
    counter: null,
    frame: null,
    distributionBoard: null,
    support: null,
    rail: null,
    slottedPlate: null,
    blindPlate: null,
    reservedBlindPlate: null,
    counterPlate: null
  };
};

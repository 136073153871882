import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import AppContext from '../../../../../context/Context';
import { categoryApi } from '../../../../../helpers/api/categoryApi';
import { showErrorsFromRequest } from '../../../../../helpers/api/showErrorsFromRequest';
import RequiredStarLabel from '../../../../common/requiredStarLabel';
import EntitySelect, {
  InputStates
} from '../../../../entitySelect/EntitySelect';
import FormGroupNote from '../../../FormGroupNote';
import ProductCategoryActionButtons from './ProductCategoryActionButtons';

export default function ProductCategorySelect({
  isRequired = false,
  isDisabled
}) {
  const [inputState, setInputState] = useState(InputStates.Loading);
  const { fetchData, setFetchData, selectedCategory, setSelectedCategory } =
    useContext(AppContext);

  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext();

  const inputName = 'categoryId';
  const categoryId = watch(inputName);

  const getCategory = useCallback(async () => {
    try {
      const response = await categoryApi.getCategory(categoryId);
      setSelectedCategory(response.data);
    } catch (e) {
      showErrorsFromRequest(e, t);
    }
  }, [categoryId, setSelectedCategory, t]);

  useEffect(() => {
    if (categoryId) {
      getCategory();
    } else {
      setSelectedCategory(null);
    }
  }, [categoryId, getCategory, setSelectedCategory]);

  return (
    <FormGroup>
      <Label htmlFor={inputName}>
        {t('Category')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name={inputName}
        control={control}
        render={({ field }) => (
          <div className="d-flex gap-2">
            <EntitySelect
              displayName={t('Category')}
              name="categoryId"
              fieldValue={field.value}
              onChange={field.onChange}
              placeholder="Not assigned"
              fetchDataFunction={categoryApi.getCategories}
              isDisabled={isDisabled}
              isFetchData={fetchData}
              setFetchData={setFetchData}
              setInputState={setInputState}
            />
            {inputState === InputStates.Loaded &&
              !selectedCategory?.isSystem && <ProductCategoryActionButtons />}
          </div>
        )}
      />
      {errors.categoryId && (
        <FormGroupNote type={'warning'}>
          {errors.categoryId.message}
        </FormGroupNote>
      )}
    </FormGroup>
  );
}

import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardFooter, Row } from 'reactstrap';

import RemoteTableWidget from '../../../../components/RemoteTableWidget';
import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import CheckUserPermissions from '../../../../hoc/checkUserPermissions';

export default function OfferTable({
  offer,
  offerEntries,
  page,
  perPage,
  setPage,
  setPerPage
}) {
  const { t } = useTranslation();
  const { items, lastPage } = offerEntries;

  const data = items?.map((item, index) => ({
    id: index,
    productLabel: item.productLabel,
    quantity: item.quantity,
    unitPrice: item.unitPrice,
    total: item.total
  }));

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: false,
      classes: 'border-200 d-none',
      headerClasses: 'border-0 d-none'
    },
    {
      dataField: 'productLabel',
      text: t('Product'),
      sort: false,
      classes: 'border-200 pl-4',
      headerClasses: 'border-0 pl-4'
    },
    {
      dataField: 'quantity',
      text: t('Quantity'),
      sort: false,
      classes: 'border-200 pl-3',
      headerClasses: 'border-0'
    },
    {
      dataField: 'unitPrice',
      text: `${t('Unit Price')} (EUR)`,
      sort: false,
      classes: 'border-200',
      headerClasses: 'border-0',
      formatter: (price, _) => Number.parseFloat(price).toFixed(2)
    },
    {
      dataField: 'total',
      text: 'Total (EUR)',
      sort: false,
      classes: 'border-200',
      headerClasses: 'border-0',
      formatter: (total, _) => Number.parseFloat(total).toFixed(2)
    }
  ];

  return (
    <Card>
      <CheckUserPermissions
        permissions={[PermissionType.PROJECT_DETAILS_OFFER.VIEW]}
      >
        <CardBody className="p-0">
          <RemoteTableWidget
            columns={columns}
            keyField="id"
            page={page}
            perPage={perPage}
            items={data}
            lastPage={lastPage}
            perPageOptions={[10, 20, 30, 50]}
            onPageChange={(page) => setPage(page)}
            onPerPageChange={(perPage) => setPerPage(perPage)}
          />
        </CardBody>
      </CheckUserPermissions>
      <CardFooter>
        <Row className="row no-gutters justify-content-end mx-3">
          <div className="col-auto">
            <table className="table table-sm table-borderless fs--1 text-right">
              <tbody>
                <tr>
                  <th className="text-900">Subtotal:</th>
                  <td className="font-weight-semi-bold">
                    {offer ? Number.parseFloat(offer.subtotal).toFixed(2) : 0}{' '}
                    EUR
                  </td>
                </tr>
                <tr>
                  <th className="text-900">{t('Discount')}:</th>
                  <td className="font-weight-semi-bold">
                    {offer ? Number.parseFloat(offer.discount).toFixed(2) : 0}{' '}
                    EUR
                  </td>
                </tr>
                <tr>
                  <th className="text-900">{t('Tax')}:</th>
                  <td className="font-weight-semi-bold">
                    {offer ? Number.parseFloat(offer.tax).toFixed(2) : 0} EUR
                  </td>
                </tr>
                <tr className="border-top">
                  <th className="text-900">Total:</th>
                  <td className="font-weight-semi-bold">
                    {offer ? Number.parseFloat(offer.total).toFixed(2) : 0} EUR
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </CardFooter>
    </Card>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import AppContext from '../../context/Context';
import { productApi } from '../../helpers/api/productApi';
import { productDataNullConvert } from '../../helpers/utils/productDataNullConvert';
import ProductModalForm from '../form/ProductModalForm';
import SideModal from './SideModal';

const emptyFormData = {
  sku: '',
  categoryId: '',
  manufacturerId: '',
  manufacturerSku: '',
  price: '',
  sellPrice: '',
  label: '',
  group: '',
  destination: '',
  width: '',
  protectionType: '',
  connectionType: '',
  amperage: '',
  differentialAmperage: '',
  differentialClass: '',
  differentialTripCurve: '',
  horizontalModules: '',
  verticalModules: '',
  hasGrounding: '',
  mountType: '',
  cableThickness: '',
  cableColorId: null,
  description: '',
  measurementUnit: ''
};

const ProductModal = () => {
  const { t } = useTranslation();
  const { editProductId, isOpenProductModal, toggleProductModal } =
    useContext(AppContext);

  const [formData, setFormData] = useState(emptyFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const fetchProductData = async () => {
    setIsLoading(true);
    try {
      const response = await productApi.getProductById(editProductId);
      setFormData(productDataNullConvert(response.data));
    } catch (error) {
      toast.error(t('Unable to retrieve product information.'));
      setError(t('Unable to retrieve product information.'));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (editProductId) {
      fetchProductData();
    } else {
      setFormData(emptyFormData);
    }
  }, [isOpenProductModal, editProductId]);

  return (
    <SideModal
      isOpen={isOpenProductModal}
      toggle={toggleProductModal}
      title="Product Form"
      subtitle={
        editProductId
          ? `${t('Edit product')} #${editProductId}`
          : t('Create a new product')
      }
      icon="edit"
    >
      {error === undefined && (
        <ProductModalForm
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          defaultValues={formData}
          setFormData={setFormData}
          setError={setError}
        />
      )}

      {error !== undefined && <p>{error}</p>}
    </SideModal>
  );
};

export default ProductModal;

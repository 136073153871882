export const consumers = {
  en: {
    translations: {
      'Add Consumer': 'Add Consumer',
      Power: 'Power',
      'Connection Type': 'Connection Type',
      Aliases: 'Aliases',
      'No aliases': 'No aliases'
    }
  },
  ro: {
    translations: {
      'Add Consumer': 'Adaugă Consumator',
      Power: 'Putere',
      'Connection Type': 'Tip Conexiune',
      Aliases: 'Pseudonime',
      'No aliases': 'Fără pseudonime'
    }
  },
  de: {
    translations: {
      'Add Consumer': 'Verbraucher hinzufügen',
      Power: 'Leistung',
      'Connection Type': 'Verbindungstyp',
      Aliases: 'Alias',
      'No aliases': 'Keine Alias'
    }
  }
};

import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ButtonIcon from '../../components/common/ButtonIcon';
import Loader from '../../components/common/Loader';
import PageHeader from '../../components/common/PageHeader';
import ProjectsColumns from '../../components/project/ProjectsColumns';
import AppContext from '../../context/Context';
import { projectApi } from '../../helpers/api/projectApi';
import { PermissionType } from '../../helpers/enums/permissionTypeEnum';
import CheckUserPermissions from '../../hoc/checkUserPermissions';
import Pagination from './components/Pagination';

const ProjectsScreen = () => {
  const { t } = useTranslation();
  const { toggleProjectModal, isOpenProjectModal, fetchData, setFetchData } =
    useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    total: 0,
    page: 1,
    lastPage: 0,
    perPage: 10
  });
  const [error, setError] = useState(undefined);

  const fetchProjects = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await projectApi.getProjects({
        page: pagination.page,
        perPage: pagination.perPage
      });
      setProjects(response.data.items);
      setPagination({
        count: response.data.count,
        total: response.data.total,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage
      });
    } catch (error) {
      toast.error(t('Unable to retrieve projects'));
      setError(t('Unable to retrieve projects'));
    }
    setIsLoading(false);
  }, [pagination.page, pagination.perPage]);

  useEffect(() => {
    if (!isOpenProjectModal) {
      fetchProjects();
    }
  }, [
    toggleProjectModal,
    pagination.page,
    pagination.perPage,
    isOpenProjectModal,
    fetchProjects
  ]);

  useEffect(() => {
    if (fetchData) {
      setFetchData(false);
      fetchProjects();
    }
  }, [fetchData, setFetchData, fetchProjects]);

  return (
    <>
      <PageHeader
        title="Projects"
        controls={() => (
          <CheckUserPermissions permissions={[PermissionType.PROJECTS.MODIFY]}>
            <ButtonIcon
              icon="plus"
              color="falcon-primary"
              size="sm"
              onClick={() => toggleProjectModal()}
            >
              {t('Add Project')}
            </ButtonIcon>
          </CheckUserPermissions>
        )}
      />
      {error && <p className="text-error">{error}</p>}
      {isLoading && <Loader />}
      <ProjectsColumns projects={projects} />
      <div className="mt-3">
        <Pagination
          {...pagination}
          onPageChanged={(page) => setPagination({ ...pagination, page: page })}
          onPerPageChanged={(perPage) =>
            setPagination({ ...pagination, page: 1, perPage: perPage })
          }
        />
      </div>
    </>
  );
};

export default ProjectsScreen;

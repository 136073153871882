import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { productApi } from '../../helpers/api/productApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { getDefaultSelectValue } from '../../helpers/utils/getDefaultSelectValue';

const ProductsSelect = ({
  placeholder,
  fieldValue,
  onChange,
  isDisabled,
  destination,
  manufacturerId,
  exactDestination = false
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    let fetchedProducts = [];
    setIsLoading(true);

    try {
      const queryParams = {
        destination,
        manufacturerId
      };

      if (exactDestination) {
        queryParams.exactDestination = true;
      }

      const response = await productApi.getProductsByDestination(queryParams);

      fetchedProducts = response.data;
    } catch (error) {
      showErrorsFromRequest(error, t);
    }

    fetchedProducts = fetchedProducts.map((product) => {
      return {
        value: product.id,
        label: product.label + ' (' + product.sku + ')'
      };
    });

    setProducts(fetchedProducts);
    setIsLoading(false);
  };

  useEffect(() => {
    setProducts([]);
    fetchProducts();
  }, [manufacturerId]);

  return (
    <>
      {isLoading && <div>{t('Loading products')} ...</div>}

      {!isLoading && (
        <Select
          options={products}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          onChange={(option) => onChange(option ? option.value : null)}
          placeholder={placeholder}
          defaultValue={getDefaultSelectValue(fieldValue, products)}
          isSearchable
          isClearable
          isDisabled={isDisabled}
        />
      )}
    </>
  );
};

export default ProductsSelect;

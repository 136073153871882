import * as echarts from 'echarts';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import moment from 'moment';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Card, CardBody, CardFooter, CardHeader, Row } from 'reactstrap';

import { rgbaColor, themeColors } from '../../../helpers/utils/utils';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';

const ProductivityCard = ({ completedProjects, monthlyCompletedProjects }) => {
  const { t } = useTranslation();
  const [month, setMonth] = useState(moment().month() + 1);

  const getOption = () => {
    return {
      tooltip: {
        padding: [10, 10],
        backgroundColor: rgbaColor(themeColors.light, 1),
        borderColor: rgbaColor(themeColors.dark, 1),
        borderWidth: 1,
        textStyle: { color: themeColors.dark }
      },
      xAxis: {
        type: 'category',
        data: monthlyCompletedProjects.map((item) => {
          return item.month;
        }),
        gridIndex: 0,
        axisTick: { show: false },
        axisLine: { show: false }
      },
      yAxis: {
        type: 'value',
        axisPointer: { show: false },
        splitLine: {
          show: false
        },
        areaStyle: {
          show: false
        },
        boundaryGap: false,
        axisLabel: {
          show: false
        },
        axisTick: { show: false },
        axisLine: { show: false }
      },
      series: [
        {
          type: 'bar',
          data: monthlyCompletedProjects.map((item) => {
            return {
              value: item.completedProjects,
              itemStyle: {
                color:
                  item.month === month
                    ? rgbaColor(themeColors.primary, 1)
                    : rgbaColor(themeColors.primary, 0.4)
              }
            };
          }),
          itemStyle: {
            color: rgbaColor(themeColors.primary, 0.4)
          },
          emphasisScale: true
        }
      ],
      grid: { right: '0px', left: '0px', bottom: '15%', top: '5%' }
    };
  };

  const onEvents = {
    click: ({ name }) => setMonth(name)
  };

  return (
    <Card className="rounded-soft">
      <CardHeader className="text-dark">
        <h4>
          <Trans>Productivity</Trans>
        </h4>
        <p className="pl-2">
          <Trans>Monthly completed projects</Trans>
        </p>
      </CardHeader>
      <CardBody>
        <ReactEchartsCore
          echarts={echarts}
          option={getOption()}
          onEvents={onEvents}
        />
      </CardBody>
      <CardFooter>
        <Row className="d-flex pl-3 pr-3">
          <div className="flex-fill fs--2">
            <p>{t('Completed Projects')}</p>
            <p className="fs-1">
              <strong>{completedProjects}</strong>
            </p>
          </div>
          {/* TODO: add new clients */}
          {/* <div className="flex-fill fs--2">
            <p>New Clients</p>
            <p className="fs-1">
              <strong>{newClients}</strong>
            </p>
          </div> */}
        </Row>
      </CardFooter>
    </Card>
  );
};

export default ProductivityCard;

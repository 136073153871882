import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Col, Input, Row } from 'reactstrap';

const Pagination = ({
  perPage = 10,
  perPageOptions = [10, 30, 50, 100],
  lastPage = 1,
  page = 1,
  onPerPageChanged,
  onPageChanged
}) => {
  const { t } = useTranslation();
  const perPageChangedHandler = (e) => {
    if (onPerPageChanged) onPerPageChanged(e.target.value);
  };

  return (
    <Row className="g-0">
      <Col className="fs--1 col-auto">
        <Input
          value={perPage}
          onChange={perPageChangedHandler}
          type="select"
          id="paginationPerPage"
          name="perPage"
          bsSize="sm"
          className="d-md-block d-none ml-2"
        >
          {perPageOptions.map((option, index) => (
            <option value={option} key={index}>
              {option}
            </option>
          ))}
        </Input>
      </Col>
      <Col className="px-2 fs--1 text-center">
        {t('Page')} {page} {lastPage ? `${t('of')} ${lastPage}` : ''}
      </Col>
      <Col className="col-auto">
        <Button
          color={page === 1 ? 'light' : 'primary'}
          size="sm"
          onClick={() => onPageChanged && onPageChanged(page - 1)}
          disabled={page === 1}
          className="px-4"
        >
          <Trans>Previous</Trans>
        </Button>
        <Button
          color={page >= lastPage ? 'light' : 'primary'}
          size="sm"
          onClick={() => onPageChanged && onPageChanged(page + 1)}
          disabled={page >= lastPage}
          className="px-4 ml-2"
        >
          <Trans>Next</Trans>
        </Button>
      </Col>
    </Row>
  );
};

// Pagination.propTypes = {
//   files: PropTypes.arrayOf(PropTypes.shape(SharedFile.propsType)).isRequired
// };

export default Pagination;

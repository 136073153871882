import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

import AppContext from '../../../context/Context';
import { clientApi } from '../../../helpers/api/clientApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { PermissionType } from '../../../helpers/enums/permissionTypeEnum';
import { clientFormSchema } from '../../../helpers/formValidations/clientFormSchema';
import { checkUserPermissions } from '../../../helpers/utils/checkUserPermissions';
import CheckUserPermission from '../../../hoc/checkUserPermissions';
import RequiredStarLabel from '../../common/requiredStarLabel';
import InputFormField from '../InputFormField';

export default function ClientModalForm({
  defaultValues,
  isLoading,
  setIsLoading
}) {
  const { t } = useTranslation();
  const { editClientId, toggleClientModal, setFetchData, user } =
    useContext(AppContext);

  const [areTermsAccepted, setAreTermsAccepted] = useState(false);

  const form = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(clientFormSchema)
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (editClientId) {
        await clientApi.updateClient(editClientId, data);
      } else {
        await clientApi.addClient(data);
      }
      toast.success(t('Client successfully created!'));
      setFetchData(true);
      toggleClientModal();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  const checkPermission = (permissions) => {
    return checkUserPermissions({
      isAdmin: user?.isAdmin,
      userPermissions: user?.permissions,
      permissions: permissions
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="form-modal-padding">
          <InputFormField
            isDisabled={
              isLoading || !checkPermission([PermissionType.CLIENT.MODIFY])
            }
            isRequired
            placeholder="John Doe"
            displayLabel="Name"
            label="name"
          />
          <InputFormField
            isDisabled={
              isLoading || !checkPermission([PermissionType.CLIENT.MODIFY])
            }
            isRequired
            placeholder="john.doe@example.com"
            displayLabel="Client Email"
            label="email"
            inputType="email"
          />
          <InputFormField
            isDisabled={
              isLoading || !checkPermission([PermissionType.CLIENT.MODIFY])
            }
            placeholder="0789123456"
            displayLabel="Phone Number"
            label="phone"
          />
          <InputFormField
            isDisabled={
              isLoading || !checkPermission([PermissionType.CLIENT.MODIFY])
            }
            placeholder={'191-103 Integer Rd.\nCorona New Mexico 08219'}
            inputType="textarea"
            displayLabel="Address"
            label="address"
            numberOfRows={3}
          />
          <InputFormField
            isDisabled={
              isLoading || !checkPermission([PermissionType.CLIENT.MODIFY])
            }
            displayLabel="Company Unique Registration Code"
            label="companyRegistrationCode"
            placeholder="J40/1234/2021"
          />
          <InputFormField
            isDisabled={
              isLoading || !checkPermission([PermissionType.CLIENT.MODIFY])
            }
            displayLabel="Company Fiscal Code"
            label="companyFiscalCode"
            placeholder="RO12345678"
          />
          <InputFormField
            isDisabled={
              isLoading || !checkPermission([PermissionType.CLIENT.MODIFY])
            }
            displayLabel="ID Number"
            label="IDNumber"
            placeholder="A12345"
          />
          <CheckUserPermission permissions={[PermissionType.CLIENT.MODIFY]}>
            <FormGroup className="d-flex">
              <Input
                id="terms"
                type="checkbox"
                className="m-0 mr-2 position-static"
                checked={!!areTermsAccepted}
                onChange={() => {
                  setAreTermsAccepted(!areTermsAccepted);
                }}
              />
              <Label htmlFor="terms" className="m-0">
                {t('Accept')}{' '}
                <a
                  href="/terms"
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {t('terms and conditions')}
                </a>{' '}
                <RequiredStarLabel />
              </Label>
            </FormGroup>
            <Button
              disabled={isLoading || !areTermsAccepted}
              type="submit"
              color="falcon-primary"
            >
              {isLoading ? '...' : t('Save')}
            </Button>
          </CheckUserPermission>
        </div>
      </Form>
    </FormProvider>
  );
}

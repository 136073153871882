export const relay = {
  en: {
    translations: {
      Relays: 'Relays',
      'Relay Form': 'Relay Form',
      'Circuits count': 'Circuits count',
      'Create relay': 'Create relay',
      'Relay saved successfully.': 'Relay saved successfully.',
      'Relay successfully deleted.': 'Relay successfully deleted.'
    }
  },
  ro: {
    translations: {
      Relays: 'Relee',
      'Relay Form': 'Formular Releu',
      'Circuits count': 'Număr de circuite',
      'Create relay': 'Creează releu',
      'Relay saved successfully.': 'Releu salvat cu succes.',
      'Relay successfully deleted.': 'Releu șters cu succes.'
    }
  },
  de: {
    translations: {
      Relays: 'Relais',
      'Relay Form': 'Relaisformular',
      'Circuits count': 'Schaltkreise zählen',
      'Create relay': 'Relais erstellen',
      'Relay saved successfully.': 'Relais erfolgreich gespeichert.',
      'Relay successfully deleted.': 'Relais erfolgreich gelöscht.'
    }
  }
};

import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { Form, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

import AppContext from '../../../context/Context';
import { offersApi } from '../../../helpers/api/offersApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { dynamicEntryFormSchema } from '../../../helpers/formValidations/dynamicEntryFormSchema';
import InputFormField from '../InputFormField';
import DynamicEntryProductSelect from './DynamicEntryProductSelect';
import DynamicEntrySubmodal from './DynamicEntrySubmodal/DynamicEntrySubmodal';
import ExpressionInput from './ExpressionInput';

export default function DynamicEntryForm({
  profileId,
  editDynamicEntryId,
  defaultValues,
  toggleModal
}) {
  const { t } = useTranslation();
  const { setFetchData } = useContext(AppContext);

  const [clickedExpressionLabel, setClickedExpressionLabel] = useState('');
  const [caretPosition, setCaretPosition] = useState(0);
  const [clickedInputRef, setClickedInputRef] = useState(null);

  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(dynamicEntryFormSchema)
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (editDynamicEntryId) {
        await offersApi.updateDynamicEntryById(
          profileId,
          editDynamicEntryId,
          data
        );
      } else {
        await offersApi.addDynamicEntryByProfileId(profileId, data);
      }
      toast.success(t('Dynamic entry successfully saved!'));
      toggleModal();
      setFetchData(true);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  return (
    <FormProvider {...form}>
      <DynamicEntrySubmodal
        clickedExpressionLabel={clickedExpressionLabel}
        setClickedExpressionLabel={setClickedExpressionLabel}
        caretPosition={caretPosition}
        setCaretPosition={setCaretPosition}
        clickedInputRef={clickedInputRef}
        setClickedInputRef={setClickedInputRef}
      />
      <Form onSubmit={form.handleSubmit(onSubmit)} style={{ width: '300px' }}>
        <div className="form-modal-padding">
          <InputFormField
            label="label"
            displayLabel="Label"
            isDisabled={isLoading}
            isRequired
          />
          <DynamicEntryProductSelect isDisabled={isLoading} />
          <ExpressionInput
            label="priceExpression"
            displayLabel="Price Expression"
            isRequired
            isDisabled={isLoading}
            setClickedExpressionLabel={setClickedExpressionLabel}
            setCaretPosition={setCaretPosition}
            clickedInputRef={clickedInputRef}
            setClickedInputRef={setClickedInputRef}
          />
          <ExpressionInput
            label="taxExpression"
            displayLabel="Tax Expression"
            isRequired
            isDisabled={isLoading}
            setClickedExpressionLabel={setClickedExpressionLabel}
            setCaretPosition={setCaretPosition}
            clickedInputRef={clickedInputRef}
            setClickedInputRef={setClickedInputRef}
          />
          <ExpressionInput
            label="discountExpression"
            displayLabel="Discount Expression %"
            isRequired
            isDisabled={isLoading}
            setClickedExpressionLabel={setClickedExpressionLabel}
            setCaretPosition={setCaretPosition}
            clickedInputRef={clickedInputRef}
            setClickedInputRef={setClickedInputRef}
          />
          <ExpressionInput
            label="quantityExpression"
            displayLabel="Quantity Expression"
            isRequired
            isDisabled={isLoading}
            setClickedExpressionLabel={setClickedExpressionLabel}
            setCaretPosition={setCaretPosition}
            clickedInputRef={clickedInputRef}
            setClickedInputRef={setClickedInputRef}
          />
          <Button
            disabled={isLoading || isLoading}
            type="submit"
            color="falcon-primary"
          >
            {isLoading ? '...' : editDynamicEntryId ? t('Save') : t('Create')}
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
}

import { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CardDeck, Col, Row } from 'reactstrap';

import ButtonIcon from '../../components/common/ButtonIcon';
import NotificationsCard from '../../components/NotificationsCard';
import ProjectsColumns from '../../components/project/ProjectsColumns';
import AppContext from '../../context/Context';
import { dashboardApi } from '../../helpers/api/dashboardApi';
import { projectApi } from '../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { PermissionType } from '../../helpers/enums/permissionTypeEnum';
import CheckUserPermissions from '../../hoc/checkUserPermissions';
import ProductivityCard from './components/ProductivityCard';
import SummaryCard from './components/SummaryCard';

const DashboardScreen = () => {
  const { toggleProjectModal, fetchData, setFetchData } =
    useContext(AppContext);
  const { t } = useTranslation();

  const [dashboardStatistics, setDashboardStatistics] = useState(null);
  const [projects, setProjects] = useState([]);

  const fetchDashboardStatistics = useCallback(async () => {
    try {
      const response = await dashboardApi.getDashboardStatistics();
      setDashboardStatistics(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, []);

  const fetchProjects = useCallback(async () => {
    const params = {
      page: 1,
      perPage: 9,
      sortBy: 'createdAt',
      sortDirection: 'desc'
    };
    try {
      const response = await projectApi.getProjects(params);
      setProjects(response.data.items);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, []);

  useEffect(() => {
    fetchDashboardStatistics();
    fetchProjects();
  }, [fetchDashboardStatistics, fetchProjects]);

  useEffect(() => {
    if (fetchData) {
      setFetchData(false);
      fetchProjects();
    }
  }, [fetchData, setFetchData, fetchProjects]);

  return (
    <>
      <Row>
        <Col className="mb-4">
          {dashboardStatistics && (
            <CardDeck>
              <SummaryCard
                title="Clients waiting"
                value={String(dashboardStatistics.clientsWaiting)}
              />
              <SummaryCard
                title="Ongoing Projects"
                value={String(dashboardStatistics.ongoingProjects)}
              />
              <SummaryCard
                title="Completed Projects"
                value={String(dashboardStatistics.completedProjects)}
              />
            </CardDeck>
          )}

          <Row className="mb-3 mt-4">
            <Col>
              <h4>
                <Trans>Latest Projects</Trans>
              </h4>
            </Col>
            <Col className="text-right">
              <CheckUserPermissions
                permissions={[PermissionType.PROJECTS.MODIFY]}
              >
                <ButtonIcon
                  icon="plus"
                  color="falcon-primary"
                  size="sm"
                  onClick={() => toggleProjectModal()}
                >
                  <Trans>Add Project</Trans>
                </ButtonIcon>
              </CheckUserPermissions>
            </Col>
          </Row>

          <ProjectsColumns projects={projects} />
        </Col>

        <Col xxl="3" xl="4" lg="12" md="12" s="12">
          <Row>
            <div className="col-xxl-12 col-xl-12 col-lg-6 mb-4">
              {dashboardStatistics && (
                <ProductivityCard
                  completedProjects={dashboardStatistics.completedProjects}
                  monthlyCompletedProjects={
                    dashboardStatistics.monthlyCompletedProjects
                  }
                />
              )}
            </div>
            <div
              className="col-xxl-12 col-xl-12 col-lg-6"
              style={{ display: 'none' }}
            >
              <NotificationsCard />
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DashboardScreen;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

import { deleteConfirmationToast } from '../../../components/deleteConfirmationToast';
import RemoteTableWidget from '../../../components/RemoteTableWidget';
import SearchInput from '../../../components/SearchInput';
import AppContext from '../../../context/Context';
import { clientApi } from '../../../helpers/api/clientApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';

const initialData = {
  count: 0,
  lastPage: 0,
  page: 1,
  perPage: 20,
  total: 0,
  items: null
};

const ClientsTable = ({ onClick }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [sort, setSort] = useState({ field: '', direction: '' });
  const [data, setData] = useState(initialData);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValues, setDebouncedSearchValues] = useState('');

  const { isOpenClientModal } = useContext(AppContext);

  const showDeleteConfirmationToast = (clientName, clientId) => {
    deleteConfirmationToast({
      title: `Please confirm you want to delete ${clientName} client?`,
      onClick: () => deleteClient(clientId),
      buttonText: 'Yes, delete!'
    });
  };

  const columns = [
    {
      dataField: 'id',
      text: 'Id',
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('id')
    },
    {
      dataField: 'name',
      text: t('Name'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('name')
    },
    {
      dataField: 'email',
      text: t('Email'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('email')
    },
    {
      dataField: 'phone',
      text: t('Phone'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('phone')
    },
    {
      dataField: 'createdAt',
      text: t('Created At'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: true,
      onSort: () => applySort('created_at'),
      formatter: (cell, row) => {
        return new Date(row.createdAt).toLocaleDateString();
      }
    },
    {
      dataField: 'createdBy',
      text: t('Created By'),
      classes: 'border-0',
      headerClasses: 'border-0',
      sort: false
    },
    {
      formatter: (_, row) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              size="sm"
              className="text-600 btn-reveal mr-3"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
            </DropdownToggle>
            <DropdownMenu right className="border py-2">
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(row.id);
                }}
              >
                {t('Edit')}
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                onClick={(e) => {
                  e.stopPropagation();
                  showDeleteConfirmationToast(row.name, row.id);
                }}
              >
                {t('Delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
      text: '',
      classes: 'border-0',
      headerClasses: 'border-0',
      dataField: ''
    }
  ];

  const applySort = (field) => {
    if (sort.field === field) {
      setSort({ field, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSort({ field, direction: 'asc' });
    }
  };

  const fetchClients = useCallback(
    async (search) => {
      let params = { page, perPage, searchTerm: search };

      if (sort.field !== '') {
        params.sortBy = sort.field;
        params.sortDirection = sort.direction;
      }

      try {
        const response = await clientApi.getClients(params);
        setData(response.data);
      } catch (error) {
        showErrorsFromRequest(error, t);
      }
    },
    [page, perPage, sort]
  );

  useEffect(() => {
    fetchClients(debouncedSearchValues);
  }, [fetchClients, page, perPage, sort, debouncedSearchValues]);

  useEffect(() => {
    if (!isOpenClientModal) fetchClients(debouncedSearchValues);
  }, [isOpenClientModal]);

  const deleteClient = async (id) => {
    try {
      await clientApi.deleteClient(id);
      toast.success(t('Client has been successfully deleted!'));
      await fetchClients();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const handleClientSearch = async (value) => {
    setPage(1);
    setDebouncedSearchValues(value);
  };

  return (
    <Card>
      <div className="d-flex align-items-end search-with-filter px-4 py-3 flex-wrap gap-2">
        <SearchInput
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onSearch={(value) => handleClientSearch(value)}
          placeholder="Name, Email, Phone"
        />
      </div>
      <CardBody className="p-0">
        <RemoteTableWidget
          columns={columns}
          keyField="id"
          page={page}
          perPage={perPage}
          items={data.items}
          lastPage={data.lastPage}
          resultsPerPage={[20, 30, 50]}
          onPageChange={(page) => setPage(page)}
          onPerPageChange={(perPage) => setPerPage(perPage)}
          rowEvents={{
            onClick: (_, row, rowIndex) => onClick(row.id, rowIndex)
          }}
        />
      </CardBody>
    </Card>
  );
};

export default ClientsTable;

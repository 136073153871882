import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CardDeck,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap';

import ButtonIcon from '../../components/common/ButtonIcon';
import PageHeader from '../../components/common/PageHeader';
import AppContext from '../../context/Context';
import { projectApi } from '../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../helpers/api/showErrorsFromRequest';
import { PermissionType } from '../../helpers/enums/permissionTypeEnum';
import { ProjectStatus } from '../../helpers/enums/statusEnum';
import CheckUserPermissions from '../../hoc/checkUserPermissions';
import DocumentationButtons from './components/DocumentationButtons';
import History from './components/History/History';
import OfferSection from './components/OfferSection/OfferSection';
import ProjectDetailsCard from './components/ProjectDetailsCard';
import ProjectInformationCard from './components/ProjectInformationCard';
import ProjectQuickStatusCard from './components/ProjectQuickStatusCard';
import SharedFiles from './components/SharedFiles';

const ProjectScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fetchData, setFetchData } = useContext(AppContext);
  const { projectId } = useParams();
  const { user } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [isDocumentationGenerated, setIsDocumentationGenerated] =
    useState(null);

  const fetchProject = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await projectApi.getProjectById(projectId);
      setProject(response.data);
      setFetchData(false);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [projectId, setFetchData, t]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject, fetchData]);

  return (
    <>
      {project && (
        <>
          <PageHeader
            title={project.name}
            controls={() => (
              <div className="d-flex justify-content-end">
                <ButtonIcon
                  icon="cog"
                  color="falcon-primary"
                  size="sm"
                  onClick={() => navigate(`/projects/${projectId}/switchboard`)}
                >
                  {t('Switchboard')}
                </ButtonIcon>
                <div className="ml-3">
                  {(project.status >= ProjectStatus.AdvancePayment ||
                    user?.isAdmin) && (
                    <DocumentationButtons
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      project={project}
                      isDocumentationGenerated={isDocumentationGenerated}
                      setIsDocumentationGenerated={setIsDocumentationGenerated}
                    />
                  )}
                </div>
              </div>
            )}
          />
          <CardDeck>
            <ProjectDetailsCard
              title="Status"
              status={project.status}
              subtitle={`${t('Last Update')} ${moment(project.updatedAt).format('DD MMM YYYY HH:mm:ss')}`}
            />
            <CheckUserPermissions
              permissions={[PermissionType.PROJECT_DETAILS.STATUS_MODIFY]}
            >
              <ProjectQuickStatusCard
                projectId={projectId}
                status={project.status}
              />
            </CheckUserPermissions>
          </CardDeck>
          <ProjectInformationCard projectId={projectId} project={project} />
          <OfferSection
            project={project}
            isDocumentationGenerated={isDocumentationGenerated}
            setIsDocumentationGenerated={setIsDocumentationGenerated}
            fetchProject={fetchProject}
          />
          <Row>
            <Col className="mt-3">
              <History projectId={projectId} />
            </Col>
            <Col md={5} lg={4} className="mt-3">
              <SharedFiles projectId={project.id} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProjectScreen;

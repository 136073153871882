import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import RequiredStarLabel from '../../../components/common/requiredStarLabel';
import FormGroupNote from '../../../components/form/FormGroupNote';
import { RelayType, RelayTypeText } from '../../../helpers/enums/relayTypeEnum';
import { getDefaultSelectValue } from '../../../helpers/utils/getDefaultSelectValue';

export default function RelayTypeSelect({ isDisabled, isRequired }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const options = [
    {
      value: RelayType.General,
      label: RelayTypeText[RelayType.General]
    },
    {
      value: RelayType.Dimmable,
      label: RelayTypeText[RelayType.Dimmable]
    },
    {
      value: RelayType.Heating,
      label: RelayTypeText[RelayType.Heating]
    }
  ];

  return (
    <FormGroup>
      <Label htmlFor="relayType">
        {t('Type')} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name="relayType"
        control={control}
        render={({ field }) => (
          <Select
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(option) => field.onChange(option.value)}
            value={getDefaultSelectValue(field.value, options)}
            className="text-capitalize"
            isDisabled={isDisabled}
          />
        )}
      />
      {errors.relayType && (
        <FormGroupNote type="warning">{errors.relayType.message}</FormGroupNote>
      )}
    </FormGroup>
  );
}

import React from 'react';
import { Col, Row } from 'reactstrap';

import ProjectCard from './ProjectCard';

const ProjectsColumns = ({ projects }) => {
  return (
    <Row xs={1} md={2} lg={3}>
      {projects.length !== 0 &&
        projects.map((project) => {
          return (
            <Col className="mb-3" key={project.id}>
              <ProjectCard key={project.id} project={project} />
            </Col>
          );
        })}
    </Row>
  );
};

export default ProjectsColumns;

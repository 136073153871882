import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap';

import { projectApi } from '../../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { handleExportProject } from '../helpers/documentationExport';

export default function DocumentationButtons({
  isLoading,
  setIsLoading,
  project,
  isDocumentationGenerated,
  setIsDocumentationGenerated
}) {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const fetchIsDocumentationGenerated = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await projectApi.getIsDocumentationGenerated(projectId);
      setIsDocumentationGenerated(response.data.isGenerated);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [projectId, setIsLoading, t]);

  useEffect(() => {
    fetchIsDocumentationGenerated();
  }, [fetchIsDocumentationGenerated]);

  const generateDocumentation = useCallback(async () => {
    setIsLoading(true);
    try {
      await projectApi.generateSwitchboardDocumentation(projectId);
      setIsDocumentationGenerated(true);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  }, [projectId, setIsLoading, t]);

  if (isDocumentationGenerated === null) return null;

  if (!isDocumentationGenerated) {
    return (
      <Button
        color="falcon-primary"
        size="sm"
        className="text-600 d-flex gap-2"
        onClick={generateDocumentation}
      >
        <span>{t('Generate Documentation')}</span>
        {isLoading && <Spinner size="sm" />}
      </Button>
    );
  }

  const handleExport = async (documentType) => {
    await handleExportProject({
      documentType,
      setIsLoading,
      projectId,
      project,
      t
    });
  };

  return (
    <>
      <UncontrolledDropdown disabled={isLoading}>
        <DropdownToggle
          color="falcon-primary"
          size="sm"
          className="text-600 btn-reveal"
        >
          <span className="mr-2">{t('Documents')}</span>
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <FontAwesomeIcon icon="ellipsis-vertical" className="fs--1" />
          )}
        </DropdownToggle>
        <DropdownMenu right className="border px-2">
          <DropdownItem onClick={() => handleExport('tableAttach')}>
            {t('Switchboard attach')} (PDF)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('tableDocumentation')}>
            {t('Switchboard documentation')} (Excel)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('cableJournal')}>
            {t('Cable journal')} (Excel)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('purchasesJournal')}>
            {t('Purchases list')} (Excel)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('tagsClamps')}>
            {t('Tags clamps')} (Excel)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('tagsConductors')}>
            {t('Tags conductors')} (Excel)
          </DropdownItem>
          <DropdownItem onClick={() => handleExport('tagsModularEquipment')}>
            {t('Tags modular equipment')} (Excel)
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

import { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label
} from 'reactstrap';

import RequiredStarLabel from '../common/requiredStarLabel';
import FormGroupNote from './FormGroupNote';

export default function InputFormField({
  isDisabled,
  isRequired = false,
  label,
  displayLabel,
  inputType = 'text',
  inputGroupText = null,
  placeholder,
  numberOfRows,
  inputClassName,
  onKeyUp
}) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current && label === 'label') {
      inputRef.current.focus();
    }
  }, [inputRef, label]);

  return (
    <FormGroup className="w-100">
      <Label htmlFor={label}>
        {t(displayLabel)} {isRequired && <RequiredStarLabel />}
      </Label>
      <Controller
        name={label}
        control={control}
        render={({ field }) => (
          <InputGroup>
            <Input
              {...field}
              type={inputType}
              disabled={isDisabled}
              placeholder={placeholder && t(placeholder)}
              rows={numberOfRows}
              className={`${inputClassName ?? ''}`}
              onKeyUp={onKeyUp}
              autoComplete="off"
              value={field.value ?? ''}
              innerRef={inputRef}
            />
            {inputGroupText && (
              <InputGroupText>{inputGroupText}</InputGroupText>
            )}
          </InputGroup>
        )}
      />
      {errors[label] && (
        <FormGroupNote type={'warning'}>{errors[label].message}</FormGroupNote>
      )}
    </FormGroup>
  );
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';

import { PermissionType } from '../../../helpers/enums/permissionTypeEnum';
import FormGroupNote from '../FormGroupNote';
import InputFormField from '../InputFormField';

export default function AliasInputField({
  aliases,
  setAliases,
  checkPermission,
  isLoading
}) {
  const { t } = useTranslation();
  const form = useFormContext();

  const onEnterPress = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      addAlias(event.target.value);
    }
  };

  const onButtonClick = () => {
    addAlias(form.getValues('alias'));
  };

  const addAlias = (value) => {
    setAliases([...aliases, value]);
    form.setValue('aliases', [...aliases, value], {
      shouldValidate: true
    });
    form.setValue('alias', '', { shouldValidate: true });
  };

  const removeAlias = (index) => {
    setAliases(aliases.filter((it, i) => i !== index));
    form.setValue(
      'aliases',
      aliases.filter((it, i) => i !== index)
    );
  };

  return (
    <div className="mb-3">
      <div
        style={{ marginBottom: '-2rem', gap: '4px' }}
        className="d-flex align-items-center"
      >
        <div className="flex-grow-1">
          <InputFormField
            isDisabled={
              isLoading || !checkPermission([PermissionType.CONSUMER.MODIFY])
            }
            isRequired
            label="alias"
            displayLabel="Aliases"
            onKeyUp={onEnterPress}
            placeholder={t('Enter the name then Enter Key to add the alias')}
          />
        </div>
        <div className="mt-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onButtonClick}
          >
            +
          </button>
        </div>
      </div>
      {form.formState.errors.aliases && (
        <FormGroupNote type={'warning'}>
          {form.formState.errors.aliases.message}
        </FormGroupNote>
      )}
      <div
        className={`d-flex flex-wrap gap-2 ${aliases.length && 'mt-4 mb-3'}`}
      >
        {aliases &&
          aliases.map((alias, index) => (
            <Badge
              color="dark"
              key={index}
              role="button"
              onClick={() => removeAlias(index)}
            >
              {alias} &nbsp; <FontAwesomeIcon icon="times" />
            </Badge>
          ))}
      </div>
    </div>
  );
}

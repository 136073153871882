import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardTitle } from 'reactstrap';

const SummaryCard = ({ title, value, comment, children }) => {
  const { t } = useTranslation();
  return (
    <Card className="mb-2 overflow-hidden" style={{ minWidth: '14rem' }}>
      <CardBody className="position-relative">
        <CardTitle>{t(title)}</CardTitle>
        <div className="d-flex align-items-end">
          <div className="p-2 flex-grow-1">
            <span className="text-500 fs--1">{t(comment)}</span>
          </div>
          <div className="d-flex align-items-end">
            <h5 className="font-weight-extra-bold text-900 fs-4">{t(value)}</h5>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

SummaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  comment: PropTypes.string,
  children: PropTypes.node
};

SummaryCard.defaultProps = {
  linkText: 'See all',
  to: '#!',
  color: 'primary'
};

export default SummaryCard;

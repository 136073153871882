import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

import { deleteConfirmationToast } from '../../../components/deleteConfirmationToast';
import RemoteTableWidget from '../../../components/RemoteTableWidget';
import SearchInput from '../../../components/SearchInput';
import AppContext from '../../../context/Context';
import { consumerApi } from '../../../helpers/api/consumerApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { ConnectionTypeText } from '../../../helpers/enums/connectionTypeEnum';
import { ConsumerTypeText } from '../../../helpers/enums/consumerTypeEnum';

const ConsumersTable = ({ onClick }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({ items: [] });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [sort, setSort] = useState({ field: '', direction: '' });
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const { fetchData, setFetchData } = useContext(AppContext);

  const fetchConsumers = async (search) => {
    try {
      const params = {
        page,
        perPage,
        sortBy: sort.field,
        sortDirection: sort.direction,
        searchTerm: search
      };

      const response = await consumerApi.getConsumers(params);

      const newData = {
        ...response.data,
        items: response.data.items.map((item) => ({
          ...item,
          type: ConsumerTypeText[item.type],
          connectionType: ConnectionTypeText[item.connectionType]
        }))
      };
      setData(newData);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  useEffect(() => {
    fetchConsumers(debouncedSearchValue);
    if (fetchData) {
      setFetchData(false);
    }
  }, [page, perPage, fetchData, debouncedSearchValue, sort]);

  const deleteConsumer = async (id) => {
    try {
      await consumerApi.deleteConsumer(id);
      toast.success(t('Consumer has been successfully deleted!'));
      await fetchConsumers(debouncedSearchValue);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const showDeleteConfirmationToast = (consumerName, consumerId) => {
    deleteConfirmationToast({
      title: `${t('Please confirm you want to delete')} ${consumerName} ${t('consumer')} ?`,
      onClick: () => deleteConsumer(consumerId),
      buttonText: t('Yes, delete!')
    });
  };

  const tableColumns = [
    {
      dataField: 'id',
      text: 'Id',
      classes: 'border-0',
      headerClasses: 'border-0'
    },
    {
      dataField: 'label',
      text: t('Label'),
      classes: 'border-0',
      headerClasses: 'border-0'
    },
    {
      dataField: 'type',
      text: t('Type'),
      classes: 'border-0',
      headerClasses: 'border-0'
    },
    {
      dataField: 'power',
      text: t('Power'),
      classes: 'border-0',
      headerClasses: 'border-0'
    },
    {
      dataField: 'connectionType',
      text: t('Connection Type'),
      classes: 'border-0',
      headerClasses: 'border-0'
    },
    {
      dataField: 'aliases',
      text: t('Aliases'),
      classes: 'border-0',
      headerClasses: 'border-0',
      formatter: (aliases) => {
        return Array.isArray(aliases) && aliases.length > 0 ? (
          <>
            {aliases.map((alias, index) => (
              <Badge className="mr-2" key={`${alias.id}_${index}`}>
                {alias.alias}
              </Badge>
            ))}
          </>
        ) : (
          <small className="text-muted">{t('No aliases')}</small>
        );
      }
    },
    {
      dataField: 'createdAt',
      text: t('Created At'),
      classes: 'border-0',
      headerClasses: 'border-0',
      formatter: (cell, row) => {
        return new Date(row.createdAt).toLocaleDateString();
      }
    },
    {
      formatter: (_, row) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              size="sm"
              className="text-600 btn-reveal mr-3"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
            </DropdownToggle>
            <DropdownMenu right className="border py-2">
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(row.id);
                }}
              >
                {t('Edit')}
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                onClick={(e) => {
                  e.stopPropagation();
                  showDeleteConfirmationToast(row.label, row.id);
                }}
              >
                {t('Delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
      text: '',
      classes: 'border-0',
      headerClasses: 'border-0',
      dataField: ''
    }
  ];

  const handleConsumerSearch = (value) => {
    setPage(1);
    setDebouncedSearchValue(value);
  };

  return (
    <Card>
      <div className="d-flex align-items-end search-with-filter px-4 py-3 flex-wrap gap-2">
        <SearchInput
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onSearch={(value) => handleConsumerSearch(value)}
          placeholder="Name, Aliases"
        />
      </div>
      <CardBody className="p-0">
        <RemoteTableWidget
          columns={tableColumns}
          keyField="id"
          page={page}
          perPage={perPage}
          perPageOptions={[20, 30, 50]}
          items={data.items}
          lastPage={data.lastPage}
          sort={sort}
          onPageChange={(e) => setPage(e)}
          onPerPageChange={(e) => setPerPage(e)}
          onSortChange={(f, d) => setSort({ field: f, direction: d })}
          rowEvents={{
            onClick: (_, row, rowIndex) => onClick(row.id, rowIndex)
          }}
        />
      </CardBody>
    </Card>
  );
};

export default ConsumersTable;

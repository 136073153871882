import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Tooltip } from 'reactstrap';

import FalconCardHeader from '../../../../components/common/FalconCardHeader';
import { offersApi } from '../../../../helpers/api/offersApi';
import { projectApi } from '../../../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';
import { PermissionType } from '../../../../helpers/enums/permissionTypeEnum';
import CheckUserPermissions from '../../../../hoc/checkUserPermissions';
import CreateOfferButtons from './CreateOfferButtons';
import OfferProfileSelect from './OfferProfileSelect';
import OfferTable from './OfferTable';

const OfferSection = ({
  project,
  isDocumentationGenerated,
  setIsDocumentationGenerated,
  fetchProject
}) => {
  const { t } = useTranslation();
  const [offer, setOffer] = useState(null);
  const [offerEntries, setOfferEntries] = useState([]);
  const [offerProfile, setOfferProfile] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const toggleTooltip = () => setIsTooltipOpen(!isTooltipOpen);

  const [updateOffer, setUpdateOffer] = useState(false);

  const fetchProfileOffer = async (profileId) => {
    try {
      const response = await offersApi.getOffersProfile(profileId);
      setOfferProfile(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const fetchOffer = useCallback(async () => {
    try {
      const response = await projectApi.getProjectOffer(project.id);
      if (response.data) {
        setOffer(response.data);
        await fetchProfileOffer(response.data.profileId);
      }
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, [project.id]);

  const fetchOfferEntries = useCallback(async () => {
    const params = { page, perPage };
    try {
      const response = await projectApi.getProjectOfferEntries(
        project.id,
        params
      );
      setOfferEntries(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  }, [project.id, page, perPage]);

  useEffect(() => {
    fetchOffer();
    fetchOfferEntries();
    setUpdateOffer(false);
  }, [fetchOffer, fetchOfferEntries, updateOffer]);

  const resetOffer = () => {
    setOffer(null);
    setOfferProfile(null);
    setOfferEntries(null);
  };

  return (
    <Card className="mt-3">
      <FalconCardHeader title="Offer">
        <div className="d-flex align-items-center gap-4">
          <div>
            {offer && offerProfile ? (
              <>
                <span className="mr-2">{t('Offer profile')}</span>
                <span className="font-weight-bold">{offerProfile.label}</span>
              </>
            ) : (
              <OfferProfileSelect setOfferProfile={setOfferProfile} />
            )}
          </div>
          <CheckUserPermissions permissions={[PermissionType.PROJECTS.MODIFY]}>
            {offer &&
              (project.switchboard.isSwitchboardDetailsIncomplete ||
                project.switchboard.isSwitchboardDirty ||
                !isDocumentationGenerated) && (
                <div>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    id="switchboard-incomplete-warning"
                    className="text-warning"
                  />
                  <Tooltip
                    isOpen={isTooltipOpen}
                    toggle={toggleTooltip}
                    target="switchboard-incomplete-warning"
                  >
                    {project.switchboard.isSwitchboardDetailsIncomplete ? (
                      <span>
                        {t(
                          'Some switchboard parameters are missing. Please add them to see all values in the offer.'
                        )}
                      </span>
                    ) : (
                      (project.switchboard.isSwitchboardDirty ||
                        !isDocumentationGenerated) && (
                        <span>
                          {t(
                            'You have some changes on the switchboard. Please recreate the offer to see it updated to the latest values.'
                          )}
                        </span>
                      )
                    )}
                  </Tooltip>
                </div>
              )}
            <CreateOfferButtons
              project={project}
              offer={offer}
              resetOffer={resetOffer}
              offerProfile={offerProfile}
              setUpdateOffer={setUpdateOffer}
              setIsDocumentationGenerated={setIsDocumentationGenerated}
              fetchProject={fetchProject}
            />
          </CheckUserPermissions>
        </div>
      </FalconCardHeader>
      {offerEntries ? (
        <OfferTable
          offer={offer}
          offerEntries={offerEntries}
          page={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      ) : (
        <div className="py-5">
          <p className="text-center">
            {t("You don't have any builded offers yet, try to generate one.")}
          </p>
        </div>
      )}
    </Card>
  );
};

export default OfferSection;

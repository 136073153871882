import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'reactstrap';

import { deleteConfirmationToast } from '../../../components/deleteConfirmationToast';
import IndexFieldSelect from '../../../components/form/IndexFieldSelect';
import InputFormField from '../../../components/form/InputFormField';
import InputFormFieldFloatValue from '../../../components/form/InputFormFieldFloatValue';
import AppContext from '../../../context/Context';
import { relayApi } from '../../../helpers/api/relayApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { PermissionType } from '../../../helpers/enums/permissionTypeEnum';
import { ProductDestination } from '../../../helpers/enums/productDestinationEnum';
import { relayFormSchema } from '../../../helpers/formValidations/relayFormSchema';
import { checkUserPermissions } from '../../../helpers/utils/checkUserPermissions';
import CheckUserPermission from '../../../hoc/checkUserPermissions';
import ProtectionSelect from '../../switchboard/components/form/ProtectionSelect';
import RelayTypeSelect from './RelayTypeSelect';

export default function RelayModalForm({
  defaultValues,
  isLoading,
  setIsLoading,
  relays,
  manufacturerId
}) {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const { toggleRelayModal, relayForEdit, setFetchData, user } =
    useContext(AppContext);

  const form = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(relayFormSchema)
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (relayForEdit) {
        await relayApi.updateRelay(projectId, relayForEdit.id, {
          label: data.label,
          idx: data.index,
          relayType: data.relayType,
          circuitsCount: data.circuitsCount,
          switchboardId: projectId,
          protectionId: data.protection.id || null
        });
      } else {
        await relayApi.createRelay(projectId, {
          label: data.label,
          idx: data.index,
          relayType: data.relayType,
          circuitsCount: data.circuitsCount,
          switchboardId: projectId,
          protectionId: data.protection.id || null
        });
      }
      toast.success(t('Relay saved successfully.'));
      toggleRelayModal();
      setFetchData(true);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  const checkPermission = (permissions) => {
    return checkUserPermissions({
      isAdmin: user?.isAdmin,
      userPermissions: user?.permissions,
      permissions: permissions
    });
  };

  const onDelete = async () => {
    try {
      await relayApi.deleteRelay(projectId, relayForEdit.id);
      toast.success(t('Relay successfully deleted.'));
      setFetchData(true);
      toggleRelayModal();
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  const showDeleteConfirmationToast = () => {
    deleteConfirmationToast({
      title: `${t('Please confirm you want to delete')} "${relayForEdit.label}"`,
      onClick: () => onDelete(),
      buttonText: t('Yes, delete!')
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <IndexFieldSelect
          isDisabled={
            isLoading || !checkPermission([PermissionType.RELAY.MODIFY])
          }
          isEdit={relayForEdit}
          items={relays}
        />
        <InputFormField
          isDisabled={
            isLoading || !checkPermission([PermissionType.RELAY.MODIFY])
          }
          isRequired
          label="label"
          displayLabel="Label"
        />
        <ProtectionSelect
          isDisabled={
            isLoading || !checkPermission([PermissionType.RELAY.MODIFY])
          }
          destination={ProductDestination.RELAY}
          manufacturerId={manufacturerId}
          exactDestination
        />
        <RelayTypeSelect
          isDisabled={
            isLoading || !checkPermission([PermissionType.RELAY.MODIFY])
          }
          isRequired
        />
        <InputFormFieldFloatValue
          label="circuitsCount"
          displayLabel="Circuits count"
          isDisabled={
            isLoading || !checkPermission([PermissionType.RELAY.MODIFY])
          }
        />
        <CheckUserPermission permissions={[PermissionType.RELAY.MODIFY]}>
          <div className="d-flex justify-content-between">
            {relayForEdit && (
              <Button
                type="button"
                disabled={isLoading}
                color="falcon-danger"
                onClick={showDeleteConfirmationToast}
              >
                {t('Delete')}
              </Button>
            )}
            <Button type="submit" disabled={isLoading} color="falcon-primary">
              {isLoading ? t('Saving') : t('Save')}
            </Button>
          </div>
        </CheckUserPermission>
      </Form>
    </FormProvider>
  );
}

import fileDownload from 'js-file-download';

import { projectApi } from '../../../helpers/api/projectApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';

export const handleExportProject = async ({
  documentType,
  setIsLoading,
  projectId,
  project,
  t
}) => {
  setIsLoading(true);
  try {
    const response = await projectApi.exportProject(projectId, documentType);
    let filename;
    switch (documentType) {
      case 'tableAttach':
        filename = `${t('Switchboard attach')} ${project.name}.pdf`;
        break;
      case 'tableDocumentation':
        filename = `${t('Switchboard documentation')} ${project.name}.xlsx`;
        break;
      case 'cableJournal':
        filename = `${t('Cable journal')} ${project.name}.xlsx`;
        break;
      case 'purchasesJournal':
        filename = `${t('Purchases list')} ${project.name}.xlsx`;
        break;
      case 'tagsClamps':
        filename = `${t('Tags clamps')} ${project.name}.xlsx`;
        break;
      case 'tagsConductors':
        filename = `${t('Tags conductors')} ${project.name}.xlsx`;
        break;
      case 'tagsModularEquipment':
        filename = `${t('Tags modular equipment')} ${project.name}.xlsx`;
        break;
      default:
        filename = `Document ${project.name}`;
    }

    fileDownload(response.data, filename);
  } catch (error) {
    showErrorsFromRequest(error, t);
  }
  setIsLoading(false);
};

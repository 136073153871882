import z from 'zod';

export const consumerFormSchema = z
  .object({
    label: z.string({ required_error: 'Label is required' }).trim().min(1, { message: 'Label is required' }),
    alias: z.string().optional(),
    aliases: z.array(z.string()).min(1, 'Alias is required'),
    type: z.coerce.number().optional(),
    power: z.coerce.number().min(1, 'Power is required'),
    connectionType: z.coerce.number().optional(),
  })
  .refine((data) => {
    if (data.aliases.length > 0 && data.alias === '') {
      return true;
    }
    return false;
  });

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Badge, ModalHeader } from 'reactstrap';

import { offersApi } from '../../../../helpers/api/offersApi';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';
import ScrollBarCustom from '../../../common/ScrollBarCustom';
import styles from './styles.module.css';

export default function DynamicEntrySubmodal({
  clickedExpressionLabel,
  caretPosition,
  setCaretPosition,
  clickedInputRef
}) {
  const { t } = useTranslation();
  const form = useFormContext();

  const [dynamicEntriesTokens, setDynamicEntriesTokens] = useState([]);

  const fetchDefaultEntriesTokens = async () => {
    try {
      const response = await offersApi.getDynamicEntriesTokens();
      setDynamicEntriesTokens(response.data);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  useEffect(() => {
    fetchDefaultEntriesTokens();
  }, []);

  const handleBadgeClick = (token) => {
    const expression = form.watch(clickedExpressionLabel);
    if (expression !== undefined && expression !== null) {
      const newExpression = [
        expression.slice(0, caretPosition),
        token,
        expression.slice(caretPosition)
      ].join('');
      form.setValue(clickedExpressionLabel, newExpression);
      setCaretPosition(caretPosition + token.length);
      clickedInputRef.focus();
    }
  };

  return (
    clickedExpressionLabel !== '' && (
      <div className={styles.dynamic_entry_submodal_container}>
        <div
          className={`position-relative px-0 vh-100 ${styles.dynamic_entry_submodal_content}`}
        >
          <ModalHeader tag="div" className="modal-header-settings">
            <div className="py-1 flex-grow-1">
              <h5 className="text-white">{t('Available tokens')}</h5>
            </div>
          </ModalHeader>
          <ScrollBarCustom
            className={`modal-body ${styles.scrollbar_container}`}
            contentProps={{
              renderer: ({ elementRef, ...restParams }) => (
                <span
                  {...restParams}
                  ref={elementRef}
                  className={classNames('p-card position-absolute border-left')}
                />
              )
            }}
          >
            <div className={styles.dynamic_entry_badges_container}>
              {dynamicEntriesTokens.map((item, index) => (
                <Badge
                  key={index}
                  className="cursor-pointer"
                  title={item?.description}
                  onClick={() => handleBadgeClick(item.token)}
                >
                  {item.token}
                </Badge>
              ))}
            </div>
          </ScrollBarCustom>
        </div>
      </div>
    )
  );
}

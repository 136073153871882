import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

import AppContext from '../../../../../context/Context';
import { categoryApi } from '../../../../../helpers/api/categoryApi';
import { showErrorsFromRequest } from '../../../../../helpers/api/showErrorsFromRequest';
import { categoryCreateFormSchema } from '../../../../../helpers/formValidations/categoryFormSchema';
import RequiredStarLabel from '../../../../common/requiredStarLabel';
import InputFormField from '../../../InputFormField';

export default function ProductCategoryFormModal({
  isModalOpen,
  toggleModal,
  categoryId
}) {
  const { setFetchData, selectedCategory, setSelectedCategory } =
    useContext(AppContext);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      name: '',
      slug: ''
    },
    mode: 'onChange',
    resolver: zodResolver(categoryCreateFormSchema)
  });

  const resetFrom = (name, slug) =>
    form.reset({ name: name ?? '', slug: slug ?? '' });

  const handleModalClose = () => {
    toggleModal();
    resetFrom();
  };

  const handleSubmitButtonClick = () => {
    if (!form.watch('slug')) {
      form.setValue(
        'slug',
        form.watch('name').replace(/\s+/g, '-').toLowerCase()
      );
    }
    form.handleSubmit(handleCategoryUpsert)();
  };

  const handleCategoryUpsert = async (data) => {
    setIsLoading(true);

    try {
      let response;
      if (selectedCategory) {
        response = await categoryApi.updateCategory(categoryId, data);
      } else {
        response = await categoryApi.addCategory(data);
      }

      handleModalClose();
      setFetchData(true);
      setSelectedCategory(response.data);
      toast.success(t('Category successfully saved!'));
    } catch (e) {
      showErrorsFromRequest(e, t);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (categoryId && selectedCategory && isModalOpen) {
      resetFrom(selectedCategory.name, selectedCategory.slug);
    } else {
      resetFrom();
    }
  }, [categoryId, isModalOpen, selectedCategory]);

  return (
    <Modal
      id="categoryModal"
      isOpen={isLoading || isModalOpen}
      toggle={isLoading ? null : toggleModal}
    >
      <ModalHeader>
        <span>
          {selectedCategory ? t('Edit category') : t('Add new category')}
        </span>
      </ModalHeader>
      <ModalBody>
        <FormProvider {...form}>
          <Form>
            <InputFormField
              displayLabel="Name"
              label="name"
              isRequired
              isDisabled={isLoading}
            />
            <InputFormField
              displayLabel="Slug"
              label="slug"
              isRequired
              isDisabled={isLoading}
            />
          </Form>
        </FormProvider>
        <p className="fs--1">
          <RequiredStarLabel />{' '}
          {t('If you want to make the slug same as name, leave it empty.')}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={handleModalClose} disabled={isLoading}>
          {t('Cancel')}
        </Button>
        <Button
          type="button"
          color="success"
          onClick={handleSubmitButtonClick}
          disabled={isLoading}
        >
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import FormGroupNote from '../../../../components/form/FormGroupNote';
import AppContext from '../../../../context/Context';
import { showErrorsFromRequest } from '../../../../helpers/api/showErrorsFromRequest';
import { roleApi } from '../../../../helpers/api/user/roleApi';
import { manageAccessFormSchema } from '../../../../helpers/formValidations/manageAccessFormSchema';

export default function ManageAccessForm({
  isEditMode,
  selectedRole,
  permissions,
  defaultValues,
  submitRef,
  setIsEditMode
}) {
  const { t } = useTranslation();
  const { setFetchData } = useContext(AppContext);

  const form = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(manageAccessFormSchema)
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (!isEditMode) {
      form.reset(defaultValues);
    }
  }, [isEditMode]);

  const onSubmit = async () => {
    const formValues = form.getValues().permissions;
    const permissions = Object.keys(formValues)
      .map((key) => (formValues[key] && key.replace(/_/g, '.')) || null)
      .filter((p) => p);
    try {
      await roleApi.addRolePermissions(selectedRole.value, permissions);
      toast.success(t('Role permissions successfully updated!'));
      setIsEditMode(false);
      setFetchData(true);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="d-flex flex-wrap" style={{ gap: '2rem 3rem' }}>
          {permissions.length !== 0 &&
            permissions.map((permission) => (
              <div
                key={permission.name}
                style={{ width: '320px' }}
                className="border px-4 py-3 rounded"
              >
                <span className="font-weight-bold">
                  {permission.name.replace(/\./g, ' ')}
                </span>
                {permission.permissions.map((p) => (
                  <div key={`permissions.${p.value.replace(/\./g, '_')}`}>
                    <FormGroup className="d-flex mt-3 align-items-center justify-content-between ml-0">
                      <div>
                        <Label
                          title={p.description}
                          htmlFor={`permissions.${p.value.replace(/\./g, '_')}`}
                          className="mb-0 mr-4"
                        >
                          {p.title}
                        </Label>
                      </div>
                      <div>
                        <Controller
                          name={`permissions.${p.value.replace(/\./g, '_')}`}
                          control={form.control}
                          render={({ field }) => (
                            <Input
                              id={`permissions.${p.value.replace(/\./g, '_')}`}
                              name={`permissions.${p.value.replace(/\./g, '_')}`}
                              type="checkbox"
                              checked={!!field.value}
                              onChange={field.onChange}
                              disabled={!selectedRole || !isEditMode}
                              className="position-static mt-0 ml-2"
                            />
                          )}
                        />
                      </div>
                    </FormGroup>
                  </div>
                ))}
              </div>
            ))}
          {form.formState.errors && (
            <FormGroupNote type="warning">
              {JSON.stringify(form.formState.errors.permissions)}
            </FormGroupNote>
          )}
        </div>
        <button ref={submitRef} className="d-none" type="submit"></button>
      </Form>
    </FormProvider>
  );
}

export const switchboardForms = {
  en: {
    translations: {
      'Switchboard Settings': 'Switchboard Settings',
      Protection: 'Protection',
      'Cables should have tile labels': 'Cables should have tile labels',
      Editing: 'Editing',
      'Create a new zone': 'Create a new zone',
      'Loading consumers': 'Loading consumers',
      'Extra Clamps': 'Extra Clamps',
      Circuits: 'Circuits',
      watts: 'watts',
      clamps: 'clamps',
      'No circuits assigned': 'No circuits assigned',
      'Enter circuit name and hit +': 'Enter circuit name and hit +',
      'Label is required.': 'Label is required.',
      'Connection type is required.': 'Connection type is required.',
      'Force cable code is required.': 'Force cable code is required.',
      'Bus cable code is required.': 'Bus cable code is required.',
      'Network cable code is required.': 'Network cable code is required.',
      'Mount type is required.': 'Mount type is required.',
      'Rear panel and counter are required for wall mount.':
        'Rear panel and counter are required for wall mount.',
      'All flush mount products are required.':
        'All flush mount products are required.',
      'Din rail is not supported yet.': 'Din rail is not supported yet.',
      'Switchboard Manufacturer': 'Switchboard Manufacturer',
      'Switchboard Products': 'Switchboard Products',
      'Rear panel is required for wall mount.':
        'Rear panel is required for wall mount.',
      'Frame is required for flush mount.':
        'Frame is required for flush mount.',
      'Meter Box is required.': 'Meter Box is required.',
      'Meter Box Plate is required.': 'Meter Box Plate is required.',
      'Rear Panel': 'Rear Panel',
      Frame: 'Frame',
      'Distribution Board': 'Distribution Board',
      Support: 'Support',
      Rail: 'Rail',
      'Slotted Plate': 'Slotted Plate',
      'Blind Plate': 'Blind Plate',
      'Reserved Blind Plate': 'Reserved Blind Plate',
      'Meter Box': 'Meter Box',
      'Meter Box Plate': 'Meter Box Plate',
      'Vertical modules per reserved row': 'Vertical modules per reserved row',
      'Number of reserved row': 'Number of reserved row'
    }
  },
  ro: {
    translations: {
      'Switchboard Settings': 'Setări Tablou',
      Protection: 'Protecție',
      'Cables should have tile labels':
        'Cablurile trebuie să aibă etichete de tip plăci',
      Editing: 'Editare',
      'Create a new zone': 'Creează o zonă nouă',
      'Loading consumers': 'Se încarcă consumatorii',
      'Extra Clamps': 'Coliere suplimentare',
      Circuits: 'Circuite',
      watts: 'watti',
      clamps: 'coliere',
      'No circuits assigned': 'Niciun circuit atribuit',
      'Enter circuit name and hit +':
        'Introduceți numele circuitului și apăsați +',
      'Label is required.': 'Eticheta este necesară.',
      'Connection type is required.': 'Este necesar tipul de conexiune.',
      'Force cable code is required.': 'Este necesar un cod de cablu forțat.',
      'Bus cable code is required.': 'Este necesar codul cablului de bus.',
      'Network cable code is required.':
        'Este necesar codul cablului de rețea.',
      'Mount type is required.': 'Este necesar tipul de montare.',
      'Rear panel and counter are required for wall mount.':
        'Panoul din spate și contorul sunt necesare pentru montarea pe perete.',
      'All flush mount products are required.':
        'Sunt necesare toate produsele pentru montare încasată.',
      'Din rail is not supported yet.': 'Sina DIN nu este încă acceptată.',
      'Switchboard Manufacturer': 'Producator de tablouri de distributie',
      'Switchboard Products': 'Modulele tabloului de distribuție',
      'Rear panel is required for wall mount.':
        'Panoul din spate este necesar pentru montarea pe perete.',
      'Frame is required for flush mount.':
        'Cadrul este necesar pentru montare încasată.',
      'Meter Box is required.': 'Cutia de contor este necesară.',
      'Meter Box Plate is required.': 'Este necesară plăcuța cutiei de contor.',
      'Rear Panel': 'Panoul din spate',
      Frame: 'Cadru',
      'Distribution Board': 'Tablă de distribuție',
      Support: 'Sprijin',
      Rail: 'Șină',
      'Slotted Plate': 'Placă cu fante',
      'Blind Plate': 'Blind Plate',
      'Reserved Blind Plate': 'Reserved Blind Plate',
      'Meter Box': 'Cuvă de contor',
      'Meter Box Plate': 'Placă de cutie de contor'
    }
  },
  de: {
    translations: {
      'Switchboard Settings': 'Schalttafel Einstellungen',
      Protection: 'Schutz',
      'Cables should have tile labels': 'Kabel sollten Fliesenetiketten haben',
      Editing: 'Bearbeitung',
      'Create a new zone': 'Erstellen Sie eine neue Zone',
      'Loading consumers': 'Verbraucher laden',
      'Extra Clamps': 'Zusätzliche Klemmen',
      Circuits: 'Schaltkreise',
      watts: 'watt',
      clamps: 'Klemmen',
      'No circuits assigned': 'Keine Schaltkreise zugewiesen',
      'Enter circuit name and hit +':
        'Geben Sie den Schaltkreisnamen ein und drücken Sie +',
      'Label is required.': 'Etikett ist erforderlich.',
      'Connection type is required.': 'Verbindungstyp ist erforderlich.',
      'Force cable code is required.':
        'Es ist ein Force-Kabelcode erforderlich.',
      'Bus cable code is required.': 'Buskabelcode ist erforderlich.',
      'Network cable code is required.': 'Netzwerkkabelcode ist erforderlich.',
      'Mount type is required.': 'Der Montagetyp ist erforderlich.',
      'Rear panel and counter are required for wall mount.':
        'Zur Wandmontage werden Rückwand und Theke benötigt.',
      'All flush mount products are required.':
        'Alle Unterputzprodukte sind erforderlich.',
      'Din rail is not supported yet.':
        'DIN-Schienen werden noch nicht unterstützt.',
      'Switchboard Manufacturer': 'Schalttafelhersteller',
      'Switchboard Products': 'Schalttafel-Produkte',
      'Rear panel is required for wall mount.':
        'Zur Wandmontage wird eine Rückwand benötigt.',
      'Frame is required for flush mount.':
        'Für die bündige Montage ist ein Rahmen erforderlich.',
      'Meter Box is required.': 'Zählerkasten wird benötigt.',
      'Meter Box Plate is required.': 'Zählerkastenplatte ist erforderlich.',
      'Rear Panel': 'Rückseite',
      Frame: 'Rahmen',
      'Distribution Board': 'Verteilertafel',
      Support: 'Unterstützung',
      Rail: 'Schiene',
      'Slotted Plate': 'Schlitzplatte',
      'Blind Plate': 'Blind Plate',
      'Reserved Blind Plate': 'Reserved Blind Plate',
      'Meter Box': 'Aufsatzwanne',
      'Meter Box Plate': 'Zählerkastenplatte'
    }
  }
};
